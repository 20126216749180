import { Global } from "../objects/global";
var checkbox1 = false;
var checkbox2 = false
var formBool = true;
document.querySelector('#submit').addEventListener('click', () => {
    if (formBool) {
        validateForm()
    }
})

document.querySelector('#checkboxOne').addEventListener('click', () => {
    if (checkbox1) {
        checkbox1 = false
        document.querySelector('#checkboxOne').src = './UI/cbOFF.png'
        // document.querySelector('#start').style.opacity = 0.5;
    }
    else {
        checkbox1 = true
        document.querySelector('#checkboxOne').src = './UI/cbON.png'
        // document.querySelector('#start').style.opacity = 1;

    }
})
document.querySelector('#checkboxTwo').addEventListener('click', () => {
    if (checkbox2) {
        checkbox2 = false
        document.querySelector('#checkboxTwo').src = './UI/cbOFF.png'
    }
    else {
        checkbox2 = true
        document.querySelector('#checkboxTwo').src = './UI/cbON.png'

    }

})
document.querySelector('#tnc').addEventListener('click', () => {
    document.querySelector('#popupIDTH').style.display = 'block'
    setTimeout(() => {
        document.querySelector('#popupIDTH').classList.add('active')
    }, 50);

})
$('.disclaimer2').click(function(){
    document.querySelector('#popupIDTH').style.display = 'block'
    setTimeout(() => {
        document.querySelector('#popupIDTH').classList.add('active')
    }, 50);
})


document.querySelector('#close2').addEventListener('click', () => {
    document.querySelector('#popupIDTH').classList.remove('active')
    setTimeout(() => {
        document.querySelector('#popupIDTH').style.display = 'none'
    }, 500);
});
function validateForm() {
    let firstname = document.getElementById('firstname').value;
    let mobile = document.getElementById('mobileNumber').value;
    let email = document.getElementById('emailId').value;
    let state = document.getElementById('state').value;
    // let dob = '11-11-1111';

    var regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const name_regx = /^[a-zA-Z ]{2,30}$/;
    let mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,11}$/;
    if (firstname.length <= 0) {
        showError('Please enter First Name')

        return;
    } else if (mobile == "" || mobile == null) {
        showError('Please enter Phone Number')


        return;
    } else if (!mobile_regx.test(mobile)) {
        showError('Please enter valid Number')


        return;
    } else if (email == "" || email == null) {
        showError('Please enter Email')
        return;
    } else if (!regex.test(email)) {
        showError('Please enter valid Email')
        return;
    }
    else if (state == "" || state == null) {
        showError('Please select State')
        return;
    }
    else if (!checkbox1) {
        showError('Please confirm your age')
        return;
    } else if (!checkbox2) {
        showError('Please confirm consent')
        return;
    }
    else if (state == 'TAMIL NADU' && (Global.answer1 == '' || Global.answer2 == '' || Global.answer3 == '')) {
        $('.box ').css('display', 'none')
        $('.quizBox ').css('display', 'block')
        $('.parentBox ').addClass('active')
        document.querySelector('#popupPage').classList.add('active')
        return;
    }
    else {
        Global.name = firstname;
        Global.mobile = mobile;
        Global.email = email
        Global.selectedState = state
        document.querySelector('.loader').style.display = 'flex'
        formBool = false
        async function hashString(str) {
            const encoder = new TextEncoder();
            const data = encoder.encode(str);
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            return hashHex;
        }
        const hashedEmail = hashString(email);
        const hashedMobile = hashString(mobile);
        // console.log(Global.U_ID);
        Global.updateClicks('submit_click')

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let tempData = null;
        var ga_cid = "1844306653.1689247851";
        ga_cid = getCookie2('_ga');

        if (typeof ga_cid === "undefined" || ga_cid === null) {
            ga_cid = "1844306653.1689247851";
        }

        let utm_term = urlParams.get("utm_term");
        let utm_medium = urlParams.get("utm_medium");
        let utm_source = urlParams.get("utm_source");
        let utm_campaign = urlParams.get("utm_campaign");
        let utm_content = urlParams.get("utm_content");
        // window.htk = "s";
        window.htk = getCookie("hubspotutk");

        function getCookie(cookie) {

            return document.cookie.split(';').reduce(function (prev, c) {
                var arr = c.split('=');
                return (arr[0].trim() === cookie) ? arr[1] : prev;
            }, undefined);
        }

        function getCookie2(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            // console.log(name);
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        let loaderTime = setInterval(() => {
            console.log("loaderTimeloaderTimeloaderTime", Global.loaderBool);
            if (Global.loaderBool) {
                Global.loaderBool = false;
                clearInterval(loaderTime)
                Global.serverObj.sendData(
                    Global.URL_DATA,
                    (response) => {
                        //    console.log(response);
                        if (response.code == 200) {
                            formBool = true
                            document.querySelector('#userImage').src = response.url

                            Global.url = response.url
                            window.adobeDataLayer.push = ({
                                "event": "linkClick",
                                "eventInfo": {
                                    "eventName": "linkClick"
                                },
                                "linkInfo": {
                                    "linkName": "chakragold:submit",
                                    "linkPosition": "bottom",
                                    "linkType": "Button"
                                },
                                "custData": {
                                    "emailID": `${hashedEmail}`,
                                    "mobileNo": `${hashedMobile}`
                                },
                                "page": {
                                    "pageName": "chakraGold:enterDetails",
                                    "language": "en_IN",
                                    "campaignName": "Pushpa2"
                                }
                            });


                            window.adobeDataLayer.push = ({
                                "event": "landed",
                                "eventInfo": {
                                    "eventName": "landed"
                                },
                                "custData": {
                                    "emailID": `${hashedEmail}`,
                                    "mobileNo": `${hashedMobile}`
                                },
                                "page": {
                                    "pageName": "chakraGold:youPoster",
                                    "language": "en_IN",
                                    "campaignName": "Pushpa2"
                                }
                            });
                            document.querySelector('.loader').style.display = 'none'

                            Global.sliderAnimation('formPage', 'sharePage', 70)

                            Global.loginStatus = true;


                        }
                        else if (response.code == 400) {
                            showError(response.message)
                        }



                    },
                    null,
                    {
                        name: Global.name,
                        mobile: Global.mobile,
                        email: Global.email,
                        checkbox: (checkbox2 ? 'true' : 'false'),
                        uid: Global.U_ID,
                        utm_term: utm_term,
                        utm_campaign: utm_campaign,
                        utm_medium: utm_medium,
                        utm_source: utm_source,
                        utm_content: utm_content,
                        ga_cid: ga_cid,
                        gameTry: Global.gameTry,
                        template: Global.slectedProduct,
                        htk: window.htk,
                        answer1: Global.answer1,
                        answer2: Global.answer2,
                        answer3: Global.answer3,
                        state: Global.selectedState,
                        facingMode: Global.facingMode,
                        saveType: 'register'
                    },
                    "POST",
                    null,
                    false
                );
            }
        }, 500);




    }
}
$('#state').change(function () {
    const selectedValue = $(this).val();
    if (selectedValue == 'TAMIL NADU') {
        $('.box ').css('display', 'none')
        $('.quizBox ').css('display', 'block')
        $('.parentBox ').addClass('active')
        document.querySelector('#popupPage').classList.add('active')
    }
});
function showError(msg) {
    $('.box ').css('display', 'none')
    $('.wrong ').css('display', 'block')
    $('.wrong ').find('.msg').html(msg)
    document.querySelector('#popupPage').classList.add('active')
}