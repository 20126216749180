import { Global } from "../objects/global"

document.querySelector('#pose').addEventListener('click', () => {
    Global.updateClicks('pose_click')
    window.adobeDataLayer.push = ({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:Try more poses here",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakragold:landingScreen",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });

    window.adobeDataLayer.push = ({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakragold:landingScreen",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });
    document.querySelector(".footerImg").classList.add("active")
    document.querySelector(".footerImg").classList.remove("active3")
    Global.sliderAnimation('introPage', 'startPage', 70)
})
document.querySelector('#quiz').addEventListener('click', () => {
    window.adobeDataLayer.push = ({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:Quizz with Pushpa every Sunday",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "Landing Page",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });
    window.open('https://bit.ly/Pushpa2xChakra','_blank')
})
