import './sass/home.scss';
import { Global } from './objects/global';
import { Server } from "./objects/callServer";
import './components/uploadPage'
// import './components/navbar'
// import './components/loginPage'
import './components/popupPage'
import './components/formPage'
import './components/productPage'
import './components/startPage'
import './components/instructionPage'
import './components/introPage'
// import './components/sidebarPage'
// import './components/rewardsPage'
// import {
// 	isMobile,
// 	isMobileOnly
// } from 'mobile-device-detect';
// import $ from 'jquery';

// import './game'
// import  "./style.scss";
var that = this;
window.onload = function () {
	setTimeout(function () {
		document.querySelector(".loader").style.display = "none";
		function isMobilePhone() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;

			// Check for Android phones but exclude Android tablets
			if (/android/i.test(userAgent) && !/tablet/i.test(userAgent)) {
				return true;
			}

			// Check for iPhone (exclude iPads)
			if (/iPhone/i.test(userAgent) && !window.MSStream) {
				return true;
			}

			return false;
		}

		if (isMobilePhone()) {
			document.querySelector(".container").style.display = "flex";
		} else {
			document.querySelector(".desktopContainer").style.display = "flex";
		}
	}, 0);


}

$(document).ready(function () {
	// Global.isMobile = isMobile
	Global.serverObj = new Server();
	let isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
	const userAgent = navigator.userAgent.toLowerCase();
	Global.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
	document.querySelector('.loader').style.display = 'block'

	// const queryString2 = window.location.search;
	// const urlParams2 = new URLSearchParams(queryString2);
	// this.utm_source2 = urlParams2.get("utm_source");
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let source = urlParams.get("utm_source");




	$.ajax({
		url: 'https://newsfeedsmartapp.com/WinWithPushpa/api/createUser.php',
		method: 'POST',
		dataType: 'json',
		data: {
			source: source
		},
		success: function (responseData) {
			// console.log(responseData);

			if (responseData.code == 200) {
				Global.U_ID = responseData.UID
				Global.gameKey = responseData.gamekey
				document.querySelector(".container").style.display = "flex";
				document.querySelector(".container").style.left = "0";
				document.querySelector('.loader').style.display = 'none'
				document.querySelector(".overlay").style.display = "block";

				// $('.box ').css('display', 'none')
				// $('.quizBox ').css('display', 'block')
				// $('.parentBox ').addClass('active')
				// document.querySelector('#popupPage').classList.add('active')

				window.adobeDataLayer.push = ({
					"event": "landed",
					"eventInfo": {
						"eventName": "landed"
					},
					"page": {
						"pageName": "chakragold:are you ready",
						"language": "en_IN",
						"campaignName": "Pushpa2",
					}
				});


			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			localStorage.clear()
			//  window.location.href = '/';
		}
	});




	this.vbool = true
	// var counter = 0
	// var looper = setInterval(() => {
	// 	counter += 1;
	// 	if (counter === 19) counter = 1;
	// 	document.querySelector("#divya").className = `top-diya-${counter}`
	// }, 150);
	this.tapSound = document.querySelector("#buttonMusic");
	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState === 'visible') {
			if (Global.volumeBool) {
				document.querySelector("#BG_Music").play();

			}
		} else {
			document.querySelector("#BG_Music").pause();
		}
	});
	document.querySelector('body').addEventListener('click', () => {
		if (this.vbool) {
			this.vbool = false;
			Global.volumeBool = true;
			document.querySelector("#BG_Music").play();
			document.querySelector('#volumeImg').src = './UI/AudioOn.png'

		}
	})
	document.querySelector('#volume').addEventListener('click', () => {

		if (Global.volumeBool) {
			Global.volumeBool = false;
			document.querySelector("#BG_Music").pause();
			document.querySelector('#volumeImg').src = './UI/AudioOff.png'
		}
		else {
			Global.volumeBool = true;
			document.querySelector("#BG_Music").currentTime = 0;
			document.querySelector("#BG_Music").play();
			document.querySelector('#volumeImg').src = './UI/AudioOn.png'
		}
	})





	this.checkbox1 = false;
	this.checkbox2 = false;

	document.getElementById("download").addEventListener("click", () => {

		Global.updateClicks('download_click')
		window.adobeDataLayer.push = ({
			"event": "linkClick",
			"eventInfo": {
				"eventName": "linkClick"
			},
			"linkInfo": {
				"linkName": "chakragold:Download",
				"linkPosition": "bottom",
				"linkType": "Button"
			},
			"page": {
				"pageName": "chakraGold:youPoster",
				"language": "en_IN",
				"campaignName": "Pushpa2"
			}
		});



	var efw = setTimeout(() => {
    clearTimeout(efw);
    
    // Open the URL in a new tab to initiate download
    var newTab = window.open(Global.url, '_blank');

    // Create the download link element and trigger the download
    var element = document.createElement("a");
    element.setAttribute("href", Global.url);
    element.setAttribute("download", `PoseWithPushpa.jpg`);
    
    // Append the element to the new tab's document if possible, otherwise append to the current document
    if (newTab && !newTab.closed) {
        newTab.document.body.appendChild(element);
    } else {
        document.body.appendChild(element);
    }
    
    element.click();
    
    // Remove the element to clean up
    if (newTab && !newTab.closed) {
        newTab.document.body.removeChild(element);
    } else {
        document.body.removeChild(element);
    }
}, 250);
	});

	function iOS() {
		// return true; 
		//remove this before go live, just for testing
		return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
			// iPad on iOS 13 detection
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
	}
	var btn = document.querySelector('.share');
	btn.addEventListener('click', async () => {

		const image = await fetch(Global.url);

		const blob = await image.blob();
		const file = new File([blob], 'tatatea.png', { type: 'image/png' });
		const filesArray = [file];
		// alert(filesArray);
		Global.updateClicks('share_click')
		window.adobeDataLayer.push = ({
			"event": "linkClick",
			"eventInfo": {
				"eventName": "linkClick"
			},
			"linkInfo": {
				"linkName": "chakragold:Share",
				"linkPosition": "bottom",
				"linkType": "Button"
			},
			"page": {
				"pageName": "chakraGold:youPoster",
				"language": "en_IN",
				"campaignName": "Pushpa2"
			}
		});
		if (iOS()) {
			var shareData = {
				files: filesArray,
			}
		} else {
			var shareData = {
				title: "tatatea",
				// text: "https://www.WinWithPushpaflavourthemoment.co.za/",
				// url: "https://www.WinWithPushpaflavourthemoment.co.za/",
				files: filesArray
			}

		}
		// var shareData = {
		// 	// title: "WinWithPushpa",
		// 	text: "https://www.WinWithPushpaflavourthemoment.co.za/?utm_source=share",
		// 	// url: "https://www.WinWithPushpaflavourthemoment.co.za/",
		// 	// files: filesArray
		// }
		try {
			await navigator.share(shareData)
			// alert('Share was successful.')
		} catch (err) {
			// alert(err)
		}
	});




});




