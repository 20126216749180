import { Global } from "../objects/global";
 Global.facingMode = "environment"; // Default to rear camera
let streamTO;
document.querySelector('#back4').addEventListener('click', () => {
    Global.updateClicks('back_click')
    Global.sliderAnimation('instructionPage', 'productPage', 70)
    
});
document.querySelector('#takePicture').addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    // document.querySelector('#volume').classList.add('active')
    Global.updateClicks('takePicture_click')
    window.adobeDataLayer.push = ({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:takePicture",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:howToPose",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });

    setTimeout(() => {
        document.querySelector('#capture').classList.add('active')
    }, 1000);
    Global.sliderAnimation('instructionPage', 'uploadPage', 80);
    Global.isMobile = true;
    document.querySelector('.footerImg').classList.add('active2')
    // Global.updateClicks('select_click')
    // 
    startCamera();

});

function startCamera() {

    // navigator.mediaDevices
    //     .getUserMedia({
    //         audio: false,
    //         video: {
    //             // width: { ideal: 1920 },
    //             // height: { ideal: 1080 },
    //             facingMode: Global.facingMode,

    //         } /* {
    //             width: document.querySelector("#capture_video").getClientRects().width,//window.innerHeight,//{ min: window.innerWidth, max: window.innerWidth },
    //             height: document.querySelector("#capture_video").getClientRects().height*1.3,//window.innerHeight,//{ min: window.innerHeight, max: window.innerHeight },
    //             facingMode: facingMode,
    //         } */,
    //     })
    //     .then((stream) => {
    //         Global.videoStream = stream;
    //         let videoObj = document.querySelector("#capture_video");
    //         videoObj.srcObject = stream;
    //         videoObj.addEventListener("loadedmetadata", () => {
    //             videoObj.play();
    //             streamTO = setTimeout(() => {
    //                 Global.streamStarted = true;
    //             }, 200);
    //         });
    //     })
    //     .catch((error) => {
    //         console.error("Error accessing media devices:", error);
    //         Global.sliderAnimation('uploadPage', 'instructionPage', 70);
    //         document.querySelector('.footerImg').classList.remove('active2')


    //     });
    navigator.mediaDevices
  .getUserMedia({
    audio: false,
    video: {
      facingMode: Global.facingMode, // Front camera
    }
  })
  .then((stream) => {
    Global.videoStream = stream;
    let videoObj = document.querySelector("#capture_video");
    videoObj.srcObject = stream;
    
    // Apply the mirroring transformation using CSS
   if(Global.facingMode=='user'){
    videoObj.style.transform = "scaleX(-1)";  // Flip horizontally (mirror effect)
    document.querySelector('#snapshot').style.transform = "scaleX(-1)";  // Flip horizontally (mirror effect)
   }else{
    videoObj.style.transform = "scaleX(1)";  // Flip horizontally (mirror effect)
    document.querySelector('#snapshot').style.transform = "scaleX(1)";  // Flip horizontally (mirror effect)
   }

    videoObj.addEventListener("loadedmetadata", () => {
      videoObj.play();
      streamTO = setTimeout(() => {
        Global.streamStarted = true;
      }, 200);
    });
  })
  .catch((error) => {
    console.error("Error accessing media devices:", error);
    Global.sliderAnimation('uploadPage', 'instructionPage', 70);
    document.querySelector('.footerImg').classList.remove('active2');
  });

}


// Add event listener for the rotate button
document.getElementById("rotateCameraBtn").addEventListener("click", () => {
    // Toggle facing mode
    Global.facingMode = Global.facingMode === "environment" ? "user" : "environment";

    // Stop the existing stream, if any
    if (Global.videoStream) {
        Global.videoStream.getTracks().forEach((track) => track.stop());
    }

    // Restart the camera with the new facing mode
    startCamera();
});
