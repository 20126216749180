import { Global } from "../objects/global";
import { Server } from "../objects/callServer";
// const video = document.getElementById('video');
//     const snapshot = document.getElementById('snapshot');
//     const overlay = document.querySelector('.overlay');
//     const captureButton = document.getElementById('capture');
// var Global.blobUrl='';
//     // Function to start the camera
//     function startCamera() {
//         const constraints = {
//             video: {
//                 facingMode: (navigator.userAgent.includes("Mobile")) ? { exact: "environment" } : "user" // Use environment (back) camera on mobile, user (front) camera on desktop
//             }
//         };

//         navigator.mediaDevices.getUserMedia(constraints)
//         .then(function (stream) {
//             video.srcObject = stream; // Set the video source to the stream
//         })
//         .catch(function (err) {
//             console.error("Error accessing camera: ", err);
//             alert("Could not access the camera. Please check your device and browser permissions.");
//         });
//     }

// startCamera(); // Start the camera when the page loads

// captureButton.addEventListener('click', function () {
//     // Show overlay while capturing
//     overlay.classList.remove('hidden');

//     // Create an ImageCapture object
//     const track = video.srcObject.getVideoTracks()[0];
//     const imageCapture = new ImageCapture(track);

//     // Capture the image
//     imageCapture.takePhoto()
//         .then(blob => {
//             const url = URL.createObjectURL(blob);
//             snapshot.src = url; // Set the captured image as the snapshot source
//             Global.blobUrl=blob
//             document.querySelector('#userImage').src=url
//             snapshot.classList.remove('hidden'); // Show the captured image
//             video.classList.add('hidden'); // Hide the video
//             overlay.classList.add('hidden'); // Hide overlay
// 			document.querySelector('.cameraContainer').style.display='none'
//             document.querySelector('.displayImageContainer').style.display = 'block';

//         })
//         .catch(err => console.error('Error capturing image:', err));
// });
document.querySelector('.backFromUpload').addEventListener('click',() => {
  // document.querySelector('#volume').classList.remove('active')
  document.querySelector('#capture').classList.remove('active')
  document.querySelector('.footerImg').classList.remove('active2')


  Global.sliderAnimation('uploadPage','instructionPage',70)
})
document.querySelector(".retake").addEventListener("click", () => {
  // Show the camera container and hide the display image container
  //
  document.querySelector(".cameraContainer").style.display = "block";
  document.querySelector(".displayImageContainer").style.display = "none";
  document.querySelector("#capture").classList.add("active");
  Global.updateClicks("retake_click");
  // document.querySelector("#rotateCameraBtn").style.display = "block";
  document.querySelector(".allu").style.display = "block";
  document.querySelector(".backFromUpload").style.display = "block";
  document.querySelector("#rotateCameraBtn").classList.remove('active')

  // Reset the video visibility
  const video = document.getElementById("capture_video");
  video.classList.remove("hidden");

  // Hide the snapshot (captured image)
  const snapshot = document.getElementById("snapshot");
  snapshot.classList.add("hidden");

  // Hide the overlay (if visible)
  const overlay = document.querySelector(".overlay");
  overlay.classList.add("hidden");
  Global.updateClicks("retake_click");
  document.querySelector('.footerImg').classList.add('active2')

  window.adobeDataLayer.push = {
    event: "linkClick",
    eventInfo: {
      eventName: "linkClick",
    },
    linkInfo: {
      linkName: "chakragold:Retake",
      linkPosition: "bottom",
      linkType: "Button",
    },
    page: {
      pageName: "chakraGold:confirmPicture",
      language: "en_IN",
      campaignName: "Pushpa2",
    },
  };

  // Restart the camera if needed (optional)
  // startCamera();
});
document.querySelector("#confirm").addEventListener("click", () => {
  // Assuming you have a blob (e.g., from a canvas or a file input)
  if (Global.confirmBool) {
    Global.confirmBool = false;
    const blob = new Blob([Global.blobUrl], { type: "image/jpeg" }); // Replace with your actual image data
    // console.log(Global.productArray[Global.slectedProduct].product_no);
    //
    Global.updateClicks("proceed_click");
    Global.sliderAnimation("uploadPage", "formPage", 70);

    // Create a FormData object to send the blob
    const formData = new FormData();
    formData.append("uid", Global.U_ID); // Replace with actual user ID
    formData.append("facingMode", Global.facingMode); // Replace with actual user ID
    formData.append("gameTry", Global.gameTry + 1); // Replace with actual user ID
    formData.append("selectedTemplate", Global.slectedProduct); // Replace with actual user ID
    formData.append("file", blob, "profile.jpg"); // Append the blob as a file
    // document.querySelector('.loader').style.display = 'flex'
    if (Global.videoStream) {
      Global.videoStream.getTracks().forEach((track) => track.stop());
      Global.videoStream = null; // Clear the videoStream variable
    }
    // Send the data to the server
    fetch(Global.URL_upload, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        /* console.log(data); */
        document.querySelector("#rotateCameraBtn").style.display = "block";
        /* console.log(data.status, " data.statusdata.status"); */

        if (data.status === 200) {
          fnremoveBG(data.image_url);
        }
        /* if (data.status === 200) {
          setTimeout(() => {
            refetchID(data.id);
          }, 1000);
        } */
      })
      .catch((err) => console.error("Error during upload:", err));
    /* if (data.imgstatus === "succeeded") {
            document.querySelector("#volume").classList.remove("active");
            document.querySelector(".allu").style.display = "block";

            document.querySelector("#capture").classList.remove("active");
            document.querySelector(".cameraContainer").style.display = "block";
            document.querySelector(".displayImageContainer").style.display =
              "none";
            Global.url = data.url;
            Global.gameTry++; // Reset the video visibility
            const video = document.getElementById("capture_video");
            video.classList.remove("hidden");
            Global.confirmBool = true;
            Global.loaderBool = true;
            // Hide the snapshot (captured image)
            const snapshot = document.getElementById("snapshot");
            snapshot.classList.add("hidden");
            document.querySelector(".loader").style.display = "none";

            const overlay = document.querySelector(".overlay");
            overlay.classList.add("hidden");

            window.adobeDataLayer.push = {
              event: "linkClick",
              eventInfo: {
                eventName: "linkClick",
              },
              linkInfo: {
                linkName: "chakragold:Proceed",
                linkPosition: "bottom",
                linkType: "Button",
              },
              page: {
                pageName: "hakraGold:confirmPicture",
                language: "en_IN",
                campaignName: "Pushpa2",
              },
            };

            window.adobeDataLayer.push = {
              event: "landed",
              eventInfo: {
                eventName: "landed",
              },
              page: {
                pageName: "chakraGold:enterDetails",
                language: "en_IN",
                campaignName: "Pushpa2",
              },
            };
          }
        } else {
          alert("Something went wrong.");
          window.location.reload();
        } */
    /* })
      .catch((err) => console.error("Error during upload:", err)); */
  }
});

function fnremoveBG(url) {
  $.ajax({
    url: "https://newsfeedsmartapp.com/WinWithPushpa/api/removeBG.php",
    type: "POST",
    data: { image_url: url },
    success: function (data) {
      /* console.log(data) */
      var imageURL = JSON.parse(data);
      var id = imageURL.id;
      /* console.log("---------" + imageURL.status, "output"); */
      if (imageURL.status != "succeeded") {
        var df = setTimeout(() => {
          clearTimeout(df);
          refetchID(id);
        }, 2000);
      } else {
        /* console.log(imageURL.output); */
        // Global.imgURL_ = imageURL.output[1];
        /* Global.imgURL_ = imageURL.output; */
        saveImg(imageURL.output);
        //doneMovetoNext();
      }
    },
    // Error handling
    error: function (error) {
      console.log(`Error ${error}`);
    },
  });
}

function refetchID(id) {
  /* console.log(id); */
  $.ajax({
    url: "https://newsfeedsmartapp.com/replicate/refetch.php",
    type: "POST",
    data: { id: id },
    success: function (data) {
      /* console.log(data) */
      var imageURL = JSON.parse(data);
      /* console.log("---------" + imageURL.status, "output"); */
      if (imageURL.status != "succeeded") {
        var df = setTimeout(() => {
          clearTimeout(df);
          refetchID(id);
        }, 2000);
      } else {
        /* console.log(imageURL.output); */
        // Global.imgURL_ = imageURL.output[1];
        /* Global.imgURL_ = imageURL.output; */
        saveImg(imageURL.output);
        //doneMovetoNext();
      }
    },
    // Error handling
    error: function (error) {
      console.log(`Error ${error}`);
    },
  });
}

function saveImg(url) {
  /* console.log(url, "eshgfjesf"); */
  $.ajax({
    url: "https://newsfeedsmartapp.com/WinWithPushpa/api/saveImg.php",
    type: "POST",
    data: {
      user_id: Global.U_ID,
      gameTry: Global.gameTry+1,
      selectedTemplate: Global.selectedTemplate,
      output: url
    },
    success: function (data) {
      /* console.log(data) */
      /* var imageURL = JSON.parse(data);
      console.log("---------" + imageURL, "output"); */
      /* if (imageURL.status != "succeeded") {
        var df = setTimeout(() => {
          clearTimeout(df);
          refetchID(id);
        }, 2000);
      } else {
        console.log(imageURL.output);
        // Global.imgURL_ = imageURL.output[1];
        Global.imgURL_ = imageURL.output;
        doneMovetoNext();
      } */
        doneMovetoNext(data);
    },
    // Error handling
    error: function (error) {
      console.log(`Error ${error}`);
    },
  });
}

function doneMovetoNext(data) {
  /* console.log("doneMovetoNext") */
  // document.querySelector("#volume").classList.remove("active");
  document.querySelector(".allu").style.display = "block";

  document.querySelector("#capture").classList.remove("active");
  document.querySelector(".cameraContainer").style.display = "block";
  document.querySelector(".displayImageContainer").style.display = "none";
  Global.url = data.url;
  Global.gameTry++; // Reset the video visibility
  const video = document.getElementById("capture_video");
  video.classList.remove("hidden");
  Global.confirmBool = true;
  Global.loaderBool = true;
  // Hide the snapshot (captured image)
  const snapshot = document.getElementById("snapshot");
  snapshot.classList.add("hidden");
  document.querySelector(".loader").style.display = "none";

  const overlay = document.querySelector(".overlay");
  overlay.classList.add("hidden");

  window.adobeDataLayer.push = {
    event: "linkClick",
    eventInfo: {
      eventName: "linkClick",
    },
    linkInfo: {
      linkName: "chakragold:Proceed",
      linkPosition: "bottom",
      linkType: "Button",
    },
    page: {
      pageName: "hakraGold:confirmPicture",
      language: "en_IN",
      campaignName: "Pushpa2",
    },
  };

  window.adobeDataLayer.push = {
    event: "landed",
    eventInfo: {
      eventName: "landed",
    },
    page: {
      pageName: "chakraGold:enterDetails",
      language: "en_IN",
      campaignName: "Pushpa2",
    },
  };
  

}

const captureImg = (video, scaleFactor) => {
  if (scaleFactor == null) {
    scaleFactor = 1;
  }
  var w = video.videoWidth * scaleFactor;
  var h = video.videoHeight * scaleFactor;
  var canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;

  var ctx = canvas.getContext("2d");
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.scale(1, 1);

  ctx.drawImage(video, -w / 2, -h / 2, w, h);

  return canvas.toDataURL("image/jpeg");
};

function base64ToBlob(base64, type = "image/jpeg") {
  const byteCharacters = atob(base64.split(",")[1]); // Decode the Base64 string
  const byteNumbers = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteNumbers], { type: type });
}
var captureBool=true
function showCountdown() {
  // Create a div element for the countdown
  const countdownDiv = document.createElement("div");
  countdownDiv.id = "countdown";
  countdownDiv.style.position = "fixed";
  countdownDiv.style.top = "50%";
  countdownDiv.style.left = "50%";
  countdownDiv.style.transform = "translate(-50%, -50%)";
  countdownDiv.style.fontSize = "3rem";
  countdownDiv.style.color = "#fff";
  // countdownDiv.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
  countdownDiv.style.padding = "20px";
  countdownDiv.style.borderRadius = "10px";
  countdownDiv.style.textAlign = "center";
  document.body.appendChild(countdownDiv);

  let countdown = 5;
  countdownDiv.innerText = countdown;

  // Update the countdown every second
  const intervalId = setInterval(() => {
    countdown--;
    countdownDiv.innerText = countdown;

    // When countdown reaches 0, clear interval and remove div
    if (countdown <1) {
      clearInterval(intervalId);
      document.body.removeChild(countdownDiv);
      captureBool=true
    }
  }, 1000);
}

document.querySelector("#capture").addEventListener("click", () => {
  if (Global.streamStarted && captureBool) {
    showCountdown();
    captureBool=false
    // Add a 3-second timer before capturing the image
    document.querySelector(".backFromUpload").style.display = "none";
    document.querySelector("#rotateCameraBtn").classList.add('active')

    setTimeout(() => {
      const cookieImg = captureImg(document.querySelector("#capture_video"), 1);
      //
      // console.log(cookieImg);
      Global.updateClicks("capture_click");
  
      document.querySelector("#capture").classList.remove("active");

      const blob = base64ToBlob(cookieImg);
      const url = URL.createObjectURL(blob);
      document.querySelector("#snapshot").src = url;
      document.querySelector("#userImage").src = url;
      Global.blobUrl = blob;
      // Show captured image and hide other elements
      document.querySelector(".cameraContainer").style.display = "none";
      document.querySelector(".displayImageContainer").style.display = "flex";
      document.querySelector('.footerImg').classList.remove('active2')

      // Global.updateClicks('capture_click')
      window.adobeDataLayer.push = {
        event: "landed",
        eventInfo: {
          eventName: "landed",
        },
        page: {
          pageName: "chakraGold:confirmPicture",
          language: "en_IN",
          campaignName: "Pushpa2",
        },
      };
  
      document.querySelector(".allu").style.display = "none";
      // document.querySelector("#rotateCameraBtn").style.display = "none";
    }, 5000); // 3-second delay
  }
});
