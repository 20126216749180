import { Global } from "../objects/global";



import '@splidejs/splide/css/core';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

// Initialize the Splide instance
const splide = new Splide('#splide1', {
    //   type: 'loop',
    interval: 2500,
    //   pauseOnHover: false,
    //   perPage: 1,
    padding: '20%',
    //   pauseOnFocus: false,

}).mount();

// Event listener for slide change
splide.on('move', (newIndex) => {
    const activeElement = splide.Components.Elements.slides[newIndex];
    // console.log('Active Slide Index:', newIndex);

    // Get the class name of the active slide element
    const classes = Array.from(activeElement.classList);
    // console.log('Active Slide Class List:', classes); // Log all classes
    // console.log(Global.productArray,activeElement.classList);


    // Find the class that is not the default classes
    const specificClass = classes.find(cls => cls !== 'splide__slide' && cls !== 'is-visible' && cls !== 'is-next' && cls !== 'is-active');

    if (specificClass) {
        console.log('Active Slide Class Name:', specificClass); // This will log "bottle_spearmint" or any other specific class
        // document.querySelector('.itemName').innerHTML = Global.products[specificClass]
        Global.slectedProduct = specificClass
        const baseClassName = specificClass.split('_').pop(); // Split by underscore and take the first part

        // document.querySelector('#product').src = `/UI/${baseClassName}.png`
        // 


    }
});

document.querySelector('#back3').addEventListener('click', () => {
    Global.updateClicks('back_click')
    Global.sliderAnimation('productPage', 'pushpaPage', 70)
    
});
document.querySelector('#select').addEventListener('click', (e) => {
    document.querySelector('#cameraTemplate').src = `./UI/${Global.slectedProduct}_camera.png`
    document.querySelector('#silhouette').src = `./UI/${Global.slectedProduct}_silhouette.png`
    Global.updateClicks('select_click')
    window.adobeDataLayer.push = ({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:Select",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:ChoosePosterTemplate",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });
    window.adobeDataLayer.push = ({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakraGold:howToPose",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });

    Global.sliderAnimation('productPage', 'instructionPage', 70);

});

